<template>
    <section id="birthday-page">
        <article id="birthday-container">
            <h1 id="birthday-title">Geburtstage<font-awesome-icon class="icon" icon="fa-solid fa-birthday-cake"/></h1>
            <h3 id="birthday-subtitle">
                Wir feiern die Geburtstagsparty bei <span class="word-highlight">Fabulas Zauberwelt!</span>
            </h3>
            <p class="birthday-text">
                🎁 Unsere Geburtstagspakete sind ab 5 Kindern buchbar. Begleitpersonen zahlen 3 €. 
            </p>
            <p class="birthday-text">
                💌 Das Geburtstagskind erhält zum Abschluss einen Gutschein für einen <b>freien Eintritt</b> beim nächsten Besuch.
            </p>
            <div id="birthday-packages-container">
                <div class="birthday-package-container">
                    <h4 class="birthday-package-title"><FontAwesomeIcon icon="fa-gift" /> Geburtstagspaket 1 <FontAwesomeIcon icon="fa-gift" /></h4>
                    <p class="birthday-package-price">pro Kind 12,00 €</p>
                    <div>
                        <p class="birthday-package-contains-title">Enthalten im Paket:</p>
                        <ul class="birthday-package-contains-list">
                            <li>Freier Eintritt</li>
                            <li>4 Karaffen Getränke - davon 2x erfrischendes Wasser und 2x leckere Apfelschorle (ab 15 Kindern 6 Karaffen)</li>
                            <li>Ein gedeckter Partytisch</li>
                        </ul>
                    </div>
                </div>
                <div class="birthday-package-container">
                    <h4 class="birthday-package-title"><FontAwesomeIcon icon="fa-gift" /> Geburtstagspaket 2 <FontAwesomeIcon icon="fa-gift" /></h4>
                    <p class="birthday-package-price">pro Kind 18,00 €</p>
                    <div>
                        <p class="birthday-package-contains-title">Enthalten im Paket:</p>
                        <ul class="birthday-package-contains-list">
                            <li>Freier Eintritt</li>
                            <li>4 Karaffen Getränke - davon 2x erfrischendes Wasser und 2x leckere Apfelschorle (ab 15 Kindern 6 Karaffen)</li>
                            <li>1x Kindermenü</li>
                            <li>Ein gedeckter Partytisch</li>
                        </ul>
                    </div>
                </div>
            </div>
            <RouterLink id="contact-text" to="/kontakt" @click="setActiveLink">Jetzt reservieren!</RouterLink>
        </article>
    </section>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import anime from 'animejs';

    export default {
    name: "BirthdayPage",
    methods: {
        setActiveLink() {
            this.$store.commit("setActiveLink", { name: "Kontakt", path: "/kontakt" });
        }
    },
    mounted() {
        anime({
            targets: '#birthday-title',
            opacity: [0, 1],
            duration: 500,
            easing: 'easeInQuad',
        });
        anime({
            targets: '#birthday-subtitle',
            opacity: [0, 1],
            delay: 500,
            duration: 500,
            easing: 'easeInQuad',
        });
        anime({
            targets: '.birthday-text',
            opacity: [0, 1],
            delay: 1000,
            duration: 500,
            easing: 'easeInQuad',
        });
        anime({
            targets: '#birthday-packages-container',
            opacity: [0, 1],
            delay: 1000,
            duration: 500,
            easing: 'easeInQuad',
        });
        anime({
            targets: '#contact-text',
            opacity: [0, 1],
            delay: 1000,
            duration: 500,
            easing: 'easeInQuad',
        });
    },
    components: { FontAwesomeIcon }
}
</script>

<style>
    #birthday-page {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        width: 100%;
        background-image: url('../assets/background.jpg');
        background-size: cover;
        background-position: center;
        justify-content: center;
        align-items: center;
    }
    #birthday-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 6rem;
        background-color: rgba(249, 239, 239, 0.7);
        width: 70%;
        height: 100%;
    }
    #birthday-title {
        font-size: 2.5rem;
        letter-spacing: 2px;
        color: #0077B6;
        justify-self: center;
        align-self: center;
        border-bottom: #0077B6 solid 2px;
        width: 90%;
        margin-bottom: 1rem;
        padding-top: 1.2rem;
    }
    #birthday-subtitle {
        font-size: 1.6rem;
        margin-bottom: 1.2rem;
        font-family: 'Bold Poppins', sans-serif;
        padding: 0 1rem 0 1rem;
    }
    .birthday-text {
        font-family: 'Poppins', sans-serif;
        font-size: 1.25rem;
        margin: .7rem;
        padding: 0rem 3rem 0 3rem;
        line-height: 1.5;
    }
    .birthday-text:last-of-type {
        margin-bottom: 2rem;
    }
    #birthday-packages-container {
        display: flex;
        flex-direction: row;
        font-family: 'Poppins', sans-serif;
    }
    .birthday-package-container {
        margin: 0 2rem 0 2rem;
    }
    .birthday-package-title {
        font-size: 1.8rem;
        color: #008000;
        font-family: 'Baloo', sans-serif;
        font-weight: 400;
    }
    .birthday-package-price {
        font-size: 1.3rem;
        margin-bottom: 1.5rem;
        font-style: italic;
        font-family: 'Bold Poppins', sans-serif;
    }
    .birthday-package-contains-title {
        font-size: 1.3rem;
        text-decoration: underline;
        margin-bottom: 1rem;
    }
    .birthday-package-contains-list {
        font-size: 1.25rem;
        list-style-position: inside;
    }
    .birthday-package-contains-list > li {
        margin: .5rem;
    }
    #contact-text {
        font-size: 1.5rem;
        margin: 2rem 0 2rem 0;
        text-transform: uppercase;
        font-family: 'Baloo', sans-serif;
        animation: beat 1.5s infinite;
    }
    @keyframes beat {
        0% { transform: scale(1); }
        50% { transform: scale(1.2); }
        100% { transform: scale(1); }
    }
    @media only screen and (max-width: 1024px) {
        #birthday-packages-container {
            flex-direction: column;
        }
        .birthday-package-container {
            margin: 0 2rem 2rem 2rem;
        }
    }
    @media only screen and (max-width: 768px) {
        #birthday-container {
            width: 90%;
        }
        .birthday-package-title {
            font-size: 1.7rem;
        }
        .birthday-text {
            margin-bottom: 1.5rem;
        }
    }
    @media only screen and (max-width: 576px) {
        #birthday-container {
            width: 100%;
        }
        .birthday-text:first-of-type {
            padding-top: 1rem;
        }
        #birthday-subtitle {
            margin: 1rem 0 1rem 0;
            padding: 0 1.5rem .0 1.5rem;
            font-size: 1.4rem;
        }
        #birthday-title {
            font-size: 2.1rem;
        }
        #contact-text {
            margin: 0 0 2rem 0;
        }
    }
</style>